.company-list {
    font-family: Arial, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.section {
    margin-bottom: 30px;
}

.section-title {
    background-color: #000080;
    color: white;
    padding: 10px;
    margin: 0;
}

.subsection {
    margin-top: 20px;
}

.subsection-title {
    background-color: #FFA500;
    color: white;
    padding: 5px 10px;
    margin: 0;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
    font-weight: bold;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

.error {
    color: red;
    font-weight: bold;
}

.filters {
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
}

.filters select,
.filters input {
    padding: 5px;
    font-size: 16px;
}