@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.bbdoy{
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #f5f7fa;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url('../../img/admin.jpeg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
}

.admin-page {
    max-width: 800px;
    width: 100%;
    margin: 20px;
    padding: 0;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.admin-header {
    background: #2780e6;
    width: 100%;
    padding: 20px;
    color: white;
    text-align: center;
    border-radius: 10px 10px 0 0;
}

.admin-header h1 {
    margin: 0;
    font-size: 2em;
    font-weight: 700;
}

.admin-main {
    padding: 20px;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.admin-nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.admin-nav li {
    margin: 15px 0;
}

.nav-link {
    display: inline-block;
    padding: 12px 25px;
    margin-left: 20px;
    margin-right: 20px;
    background-color: #2780e6;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    font-weight: 500;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.nav-link:hover {
    background-color: #45a049;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.admin-footer {
    background: #2780e6;
    width: 100%;
    color: white;
    padding: 10px;
    text-align: center;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

.admin-footer p {
    margin: 0;
    font-size: 0.9em;
}