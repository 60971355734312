.register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f0f2f5;
    padding: 20px;
}

.register-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 40px;
    width: 100%;
    max-width: 400px;
}

.register-card h2 {
    color: #1a3a5e;
    text-align: center;
    margin-bottom: 30px;
}

.register-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    color: #4a4a4a;
    font-weight: 500;
}

.form-group input,
.form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.form-group input:focus,
.form-group select:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.error {
    color: #d32f2f;
    font-size: 14px;
    margin-top: 5px;
}

.submit-btn {
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 12px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-btn:hover {
    background-color: #357abd;
}

.submit-btn:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.submit-message {
    text-align: center;
    margin-top: 20px;
    padding: 10px;
    border-radius: 4px;
}

.submit-message.success {
    background-color: #d4edda;
    color: #155724;
}

.submit-message.error {
    background-color: #f8d7da;
    color: #721c24;
}